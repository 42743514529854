<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Vehicles</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" text to="/vehicle/create">
          <v-icon left dark>mdi-plus</v-icon>New Vehicle
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-text-field dense v-model="search" label="Search"></v-text-field>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="vehicles"
              :search="search"
              item-key="id"
              :single-select="singleSelect"
            >
              <template v-slot:item.RegistrationNO="{ item }">
                <v-btn color="primary" text :to="`/vehicle/${item.id}`">{{
                  item.RegistrationNO
                }}</v-btn>
              </template>

              <template v-slot:item.Status="{ item }">
                <v-btn
                  class="btnLink"
                  color="accent"
                  text
                  @click="changeStatus(item)"
                  >{{ item.Status == 1 ? "Active" : "In Active" }}</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <!-- snackbar -->
        <snackbar ref="snackbar"></snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    vehicles: [],
    loader: false,
    dialog: false,
    singleSelect: true,
    headers: [
      { text: "Registration Number", value: "RegistrationNO" },
      { text: "Make", value: "Make" },
      // { text: "Model", value: "Model" },
      // { text: "Brand", value: "Brand" },
      { text: "Year", value: "Year" },
      //  { text: "Color", value: "Color" },
      { text: "Capacity(kgs)", value: "Capacity" },
      { text: "Status", value: "Status" },
    ],
  }),
  methods: {
    getvehicles() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/vehicles`)
        .then((res) => {
          self.vehicles = res.ResponseData;
          console.log(res, "res");
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getvehicles();
  },
};
</script>